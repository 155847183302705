import { bindable, inject }                    from 'aurelia-framework';
import { AppContainer }                        from 'resources/services/app-container';
import { BaseViewModel }                       from 'base-view-model';
import { ConformityEvaluationsRepository }     from 'modules/legislation/client/conformity-evaluations/services/repository';
import { FilterFormSchema }                    from 'modules/legislation/client/conformity-evaluations/filter-form-schema';
import { GenerateConformityEvaluationReport }  from 'modules/legislation/client/conformity-evaluations/report/index';
import { ConformityEvaluationStage }           from 'modules/legislation/models/conformity-evaluation-stage';
import { DuplicateConformityEvaluationDialog } from 'modules/legislation/client/conformity-evaluations/dialogs/duplicate/index';
import { DialogService }                       from 'aurelia-dialog';

@inject(AppContainer, ConformityEvaluationsRepository, FilterFormSchema, DialogService)
export class ListConformityEvaluations extends BaseViewModel {

    headerTitle;
    listingId;

    @bindable newRecordRoute;
    @bindable editRecordRoute;
    @bindable viewRecordRoute;

    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filterFormSchema
     * @param dialogService
     */
    constructor(appContainer, repository, filterFormSchema, dialogService) {
        super(appContainer);

        this.repository       = repository;
        this.filterFormSchema = filterFormSchema;
        this.dialogService    = dialogService;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.client.conformity-evaluations.manage',
            'legislation.client.conformity-evaluations.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:           'resource.legislation.conformity-evaluation',
            repository:         this.repository,
            edit:               this.editRecordRoute,
            destroy:            {
                visible: (row) => row.stage.id !== ConformityEvaluationStage.EXECUTED,
            },
            show:               {
                action: (row) => this.appContainer.router.navigateToRoute(this.viewRecordRoute, {id: row.id}),
            },
            actionsContextMenu: true,
            actions:            [
                {
                    icon:    'icon-copy3',
                    tooltip: 'form.button.duplicate',
                    action:  (row) => {
                        this.dialogService
                            .open({
                                viewModel: DuplicateConformityEvaluationDialog,
                                model:     row,
                            })
                            .whenClosed((dialogResponse) => {
                                if (!dialogResponse.wasCancelled && dialogResponse.output.model) {
                                    this.appContainer.router.navigateToRoute('legislation.client.conformity-evaluations.execution.edit', {id: dialogResponse.output.model.id});
                                }
                            });
                    },
                    visible: (row) => row.stage.id !== ConformityEvaluationStage.IN_PREPARATION,
                },
                {
                    icon:    'icon-file-pdf',
                    tooltip: 'form.button.report',
                    action:  (row) => this.repository.downloadReport(row.id),
                    visible: (row) => row.stage.id === ConformityEvaluationStage.EXECUTED,
                },
                {
                    icon:    'icon-file-pdf',
                    tooltip: 'form.button.generate-report',
                    action:  (row) => this.dialogService.open({viewModel: GenerateConformityEvaluationReport, model: row}),
                },
            ],
            options:            [],
            buttons:            [],
            selectable:         true,
            sorting:            {
                column:    0,
                direction: 'asc',
            },
            columns:            [
                {
                    data:  'title',
                    name:  'conformity_evaluations.title',
                    title: 'form.field.title',
                },
                {
                    data:  'expected_start_date',
                    name:  'conformity_evaluations.expected_start_date',
                    title: 'form.field.expected-start-date',
                },
                {
                    data:  'expected_end_date',
                    name:  'conformity_evaluations.expected_end_date',
                    title: 'form.field.expected-end-date',
                },
                {
                    data:            'created_at',
                    name:            'conformity_evaluations.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:  'creator',
                    name:  'users.name',
                    title: 'form.field.created-by',
                },
            ],
        };
    }
}
