import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class ConformityEvaluationLinesRepository {

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Performs a search given some criteria
     *
     * @param conformityEvaluationId
     * @param criteria
     *
     * @returns {*}
     */
    search(conformityEvaluationId, criteria) {
        return this.httpClient.post(`legislation/client/conformity-evaluations/${conformityEvaluationId}/lines/search`, criteria);
    }

    /**
     * Refreshes conformity evaluation lines
     *
     * @param conformityEvaluationId
     *
     * @returns {*}
     */
    refresh(conformityEvaluationId) {
        return this.httpClient.patch(`legislation/client/conformity-evaluations/${conformityEvaluationId}/lines/refresh`);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get(`legislation/client/conformity-evaluations/lines/${id}`);
    }

    /**
     * Updates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(id, data) {
        return this.httpClient.put(`legislation/client/conformity-evaluations/lines/${id}`, data);
    }

    /**
     * Destroys a record
     *
     * @param id
     *
     * @returns {*}
     */
    destroy(id) {
        return this.httpClient.delete(`legislation/client/conformity-evaluations/lines/${id}`);
    }

    /**
     * Destroys selected records
     *
     * @param ids
     *
     * @returns {*}
     */
    destroySelected(ids) {
        return this.httpClient.delete('legislation/client/conformity-evaluations/lines/destroy-selected', {ids: ids});
    }

}
