import { bindable, inject }                             from 'aurelia-framework';
import { AppContainer }                                 from 'resources/services/app-container';
import { ListConformityEvaluations }                    from 'modules/legislation/client/conformity-evaluations/index';
import { ConformityEvaluationsExecutedStageRepository } from 'modules/legislation/client/conformity-evaluations/executed/services/repository';
import { FilterFormSchema }                             from 'modules/legislation/client/conformity-evaluations/filter-form-schema';
import { DialogService }                                from 'aurelia-dialog';

@inject(AppContainer, ConformityEvaluationsExecutedStageRepository, FilterFormSchema, DialogService)
export class ListExecutedStageConformityEvaluations extends ListConformityEvaluations {

    headerTitle = 'listing.legislation.conformity-evaluations.executed';
    listingId   = 'legislation-client-conformity-evaluations-executed-stage-listing';

    @bindable viewRecordRoute = 'legislation.client.conformity-evaluations.executed.view';

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filterFormSchema
     * @param dialogService
     */
    constructor(appContainer, repository, filterFormSchema, dialogService) {
        super(appContainer, repository, filterFormSchema, dialogService);
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.client.conformity-evaluations.manage',
            'legislation.client.conformity-evaluations.view',
        ]);
    }

}
