import { ConformityEvaluationPreparationForm as BaseConformityEvaluationPreparationForm } from 'modules/legislation/client/conformity-evaluations/preparation/form';

export class CreateConformityEvaluation extends BaseConformityEvaluationPreparationForm {

    headerTitle = 'form.title.create-new-record';
    formId      = 'legislation-client-conformity-evaluations-preparation-stage-creation-form';

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.client.conformity-evaluations.manage',
            'legislation.client.conformity-evaluations.create',
        ]);
    }

    /**
     * Creates conformity evaluation and closes it
     */
    conclude(checklist = []) {
        this.alert = null;

        this.model.checklist = checklist;

        this.repository.createAndConclude(this.model)
            .then((response) => this.defaultFormSubmittedCallback(response, 'legislation.client.conformity-evaluations.preparation.index'))
            .catch(console.log.bind(console));
    }

}
