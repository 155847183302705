import { inject }                                          from 'aurelia-framework';
import { AppContainer }                                    from 'resources/services/app-container';
import { BaseFormViewModel }                               from 'base-form-view-model';
import { ConformityEvaluationsPreparationStageRepository } from 'modules/legislation/client/conformity-evaluations/preparation/services/repository';
import { FormSchema }                                      from 'modules/legislation/client/conformity-evaluations/preparation/view/form-schema';

@inject(AppContainer, ConformityEvaluationsPreparationStageRepository, FormSchema)
export class ConformityEvaluationPreparationViewForm extends BaseFormViewModel {

    headerTitle = 'form.title.view-record';
    formId      = 'legislation-client-conformity-evaluations-preparation-stage-view-form';

    applicabilities = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.client.conformity-evaluations.manage',
            'legislation.client.conformity-evaluations.view',
        ]);
    }

    /**
     * Handles activate event
     *
     * @returns {Promise}
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => {
            this.generalInformationSchema = this.formSchema.generalInformationSchema(this);
            this.intervenersSchema        = this.formSchema.intervenersSchema(this);
            this.scopeSchema              = this.formSchema.scopeSchema(this);
            this.applicableSectorsSchema  = this.formSchema.applicableSectorsSchema(this);
            this.globalButtonsSchema      = this.formSchema.globalButtonsSchema(this);
        });
    }

    /**
     * Handles attached event
     */
    attached() {
        super.attached();

        this.headerSubtitle = this.model.title;
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise}
     */
    fetchData(params) {
        return this.repository
            .find(params.id)
            .then((conformityEvaluation) => {
                this.initialModel.assign(conformityEvaluation);
                this.model.assign(conformityEvaluation);
            })
            .then(() => this.repository.clientApplicabilities(this.model.diploma_classifications, this.model.applicable_sectors))
            .then((applicabilities) => this.applicabilities.splice(0, this.applicabilities.length, ...applicabilities));
    }

}
