import { transient } from 'aurelia-framework';
import { BaseModel } from 'modules/core/models/base-model';

@transient()
export class FormSchema {

    /**
     * Returns a new instance of the model
     *
     * @returns {BaseModel}
     */
    model() {
        return new BaseModel();
    }

    /**
     * Returns the form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.diploma_summary = {
            type:       'textarea',
            key:        'diploma_summary',
            id:         'conformity_evaluation_line_diploma_summary',
            label:      'form.field.summary',
            size:       12,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.diploma_resume = {
            type:       'textarea',
            key:        'diploma_resume',
            id:         'conformity_evaluation_line_diploma_resume',
            label:      'form.field.resume',
            size:       12,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.applicable_sector_name = {
            type:       'text',
            key:        'applicable_sector_name',
            id:         'conformity_evaluation_line_applicable_sector_name',
            label:      'form.field.applicable-sector',
            size:       12,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.applicability_notes = {
            type:       'textarea',
            key:        'applicability_notes',
            id:         'conformity_evaluation_line_applicability_notes',
            label:      'form.field.applicability-notes',
            size:       12,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        return [
            [this.diploma_summary],
            [this.diploma_resume],
            [this.applicable_sector_name],
            [this.applicability_notes],
        ];
    }

}
