import { bindable, inject }                                from 'aurelia-framework';
import { AppContainer }                                    from 'resources/services/app-container';
import { ListConformityEvaluations }                       from 'modules/legislation/client/conformity-evaluations/index';
import { ConformityEvaluationsPreparationStageRepository } from 'modules/legislation/client/conformity-evaluations/preparation/services/repository';
import { FilterFormSchema }                                from 'modules/legislation/client/conformity-evaluations/filter-form-schema';
import { DialogService }                                   from 'aurelia-dialog';

@inject(AppContainer, ConformityEvaluationsPreparationStageRepository, FilterFormSchema, DialogService)
export class ListPreparationStageConformityEvaluations extends ListConformityEvaluations {

    headerTitle = 'listing.legislation.conformity-evaluations.in-preparation';
    listingId   = 'legislation-client-conformity-evaluations-preparation-stage-listing';

    @bindable newRecordRoute  = 'legislation.client.conformity-evaluations.preparation.create';
    @bindable editRecordRoute = 'legislation.client.conformity-evaluations.preparation.edit';
    @bindable viewRecordRoute = 'legislation.client.conformity-evaluations.preparation.view';

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filterFormSchema
     * @param dialogService
     */
    constructor(appContainer, repository, filterFormSchema, dialogService) {
        super(appContainer, repository, filterFormSchema, dialogService);
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.client.conformity-evaluations.manage',
            'legislation.client.conformity-evaluations.view',
        ]);
    }

}
