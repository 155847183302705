import { bindable, inject }                from 'aurelia-framework';
import { AppContainer }                    from 'resources/services/app-container';
import { BaseFormViewModel }               from 'base-form-view-model';
import { ConformityEvaluationsRepository } from 'modules/legislation/client/conformity-evaluations/services/repository';
import { DialogController }                from 'aurelia-dialog';
import { FormSchema }                      from 'modules/legislation/client/conformity-evaluations/dialogs/duplicate/form-schema';

@inject(AppContainer, ConformityEvaluationsRepository, DialogController, FormSchema)
export class DuplicateConformityEvaluationDialog extends BaseFormViewModel {

    formId = 'legislation-client-conformity-evaluations-duplicate';

    @bindable settings = {
        buttons: false,
        size:    'modal-md',
        title:   'dialog.title.duplicate-conformity-evaluation',
        body:    'dialog.body.duplicate-conformity-evaluation',
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogController
     * @param formSchema
     */
    constructor(appContainer, repository, dialogController, formSchema) {
        super(appContainer);

        this.repository       = repository;
        this.dialogController = dialogController;
        this.formSchema       = formSchema;
    }

    /**
     * Handles activate event
     */
    activate(originalConformityEvaluation, navigationInstructions) {
        super.activate(navigationInstructions);

        this.originalConformityEvaluation = originalConformityEvaluation;

        this.model  = this.formSchema.model();
        this.schema = this.formSchema.schema(this);
    }

    /**
     * Submits dialog
     */
    submit() {
        this.alert = null;

        this.repository
            .duplicate(this.originalConformityEvaluation.id, this.model)
            .then((response) => {
                if (response.status === true) {
                    this.appContainer.notifier.successNotice(response.message);

                    return this.dialogController.ok(response);
                }

                this.alert = this.alertMessage(response.status, response.message, response.errors);
            });
    }

    /**
     * Cancels dialog
     */
    cancel() {
        return this.dialogController.cancel();
    }

}
