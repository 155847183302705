export class ReportFilter {

    include_actions              = null;
    include_legal_connections    = null;
    include_resume               = null;
    include_schedule             = null;
    conformity_evaluation_states = [];
    diploma_classifications      = [];
    applicable_sectors           = [];

}

export class FormSchema {

    /**
     * Returns a new instance of the model
     *
     * @returns {ReportFilter}
     */
    model() {
        return new ReportFilter();
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.include_schedule = {
            type:  'boolean-options-select',
            key:   'include_schedule',
            label: 'form.field.include-schedule',
            size:  3,
        };

        this.include_actions = {
            type:  'boolean-options-select',
            key:   'include_actions',
            label: 'form.field.include-actions',
            size:  3,
        };

        this.include_legal_connections = {
            type:  'boolean-options-select',
            key:   'include_legal_connections',
            label: 'form.field.include-legal-connections',
            size:  3,
        };

        this.include_resume = {
            type:  'boolean-options-select',
            key:   'include_resume',
            label: 'form.field.include-resume',
            size:  3,
        };

        this.conformity_evaluation_states = {
            type:         'multiselect-native',
            key:          'conformity_evaluation_states',
            label:        'page.sidebar.conformity-evaluation-statuses',
            size:         3,
            required:     false,
            remoteSource: viewModel.conformityEvaluationStatusesRepository.activeWithNotEvaluated.bind(viewModel.conformityEvaluationStatusesRepository),
        };

        this.applicability_types = {
            type:         'multiselect-native',
            key:          'applicability_types',
            label:        'page.sidebar.applicability-types',
            size:         3,
            required:     false,
            remoteSource: viewModel.applicabilityTypesRepository.active.bind(viewModel.applicabilityTypesRepository),
        };

        this.diploma_publication_date_from = {
            type:     'material-ui-date-picker',
            key:      'diploma_publication_date_from',
            id:       'applicability_diploma_publication_date_from',
            label:    'form.field.publication-date(from)',
            size:     3,
            required: false,
        };

        this.diploma_publication_date_to = {
            type:     'material-ui-date-picker',
            key:      'diploma_publication_date_to',
            id:       'applicability_diploma_publication_date_to',
            label:    'form.field.publication-date(to)',
            size:     3,
            required: false,
        };

        this.diploma_classifications = {
            type:         'fancytree',
            key:          'diploma_classifications',
            label:        'form.field.classifications',
            size:         6,
            required:     false,
            remoteSource: () => viewModel.diplomaClassificationsRepository.conformityEvaluationTree(viewModel.model.id),
            settings:     {
                selectMode:     3,
                controlButtons: true,
            },
        };

        this.applicable_sectors = {
            type:         'fancytree',
            key:          'applicable_sectors',
            label:        'form.field.applicable-sectors',
            size:         6,
            required:     false,
            remoteSource: () => viewModel.companyOrganizationsRepository.tree(true),
            settings:     {
                selectMode:     3,
                controlButtons: true,
            },
        };

        return [
            [this.include_schedule, this.include_actions, this.include_legal_connections, this.include_resume],
            [this.conformity_evaluation_states, this.applicability_types, this.diploma_publication_date_from, this.diploma_publication_date_to],
            [this.diploma_classifications, this.applicable_sectors],
        ];
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    globalSchema(viewModel) {
        this.cancelButton = {
            type:       'button',
            label:      'form.button.cancel',
            action:     () => viewModel.dialogController.cancel(),
            attributes: {
                class: 'btn btn-link float-right',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.generateReportButton = {
            type:       'submit',
            label:      'form.button.generate-report',
            action:     () => viewModel.downloadFilteredReport(),
            attributes: {
                class: 'btn btn-primary float-right',
            },
            icon:       {
                attributes: {
                    class: 'icon-file-pdf',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.generateReportButton,
                this.cancelButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
