import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';
import { Downloader }       from 'resources/services/downloader';

@inject(CustomHttpClient, Downloader)
export class ConformityEvaluationsRepository {

    /**
     * Constructor
     *
     * @param httpClient
     * @param downloader
     */
    constructor(httpClient, downloader) {
        this.httpClient = httpClient;
        this.downloader = downloader;
    }

    /**
     * Duplicates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    duplicate(id, data) {
        return this.httpClient.post(`legislation/client/conformity-evaluations/${id}/duplicate`, data);
    }

    /**
     * Downloads conformity report
     *
     * @param id
     *
     * @returns {*}
     */
    downloadReport(id) {
        return this.httpClient.rawGet(`legislation/client/conformity-evaluations/${id}/report`)
            .then((response) => response.blob())
            .then(result => this.downloader.download(result, 'conformity_report', 'pdf'));
    }

    /**
     * Downloads filtered conformity report
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    downloadFilteredReport(id, data) {
        return this.httpClient.rawPost(`legislation/client/conformity-evaluations/${id}/filtered-report`, data)
            .then((response) => response.blob())
            .then(result => this.downloader.download(result, 'conformity_report', 'pdf'));
    }

    /**
     * Destroys a record
     *
     * @param id
     *
     * @returns {*}
     */
    destroy(id) {
        return this.httpClient.delete(`legislation/client/conformity-evaluations/${id}`);
    }

    /**
     * Destroys selected records
     *
     * @param ids
     *
     * @returns {*}
     */
    destroySelected(ids) {
        return this.httpClient.delete('legislation/client/conformity-evaluations/destroy-selected', {ids: ids});
    }
}
