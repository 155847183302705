import { bindable, bindingMode, inject }                   from 'aurelia-framework';
import { AppContainer }                                    from 'resources/services/app-container';
import { BaseFormViewModel }                               from 'base-form-view-model';
import { ConformityEvaluationsPreparationStageRepository } from 'modules/legislation/client/conformity-evaluations/preparation/services/repository';
import { DiplomasRepository }                              from 'modules/legislation/diplomas/services/repository';
import { ViewDiplomaModal }                                from 'modules/legislation/diplomas/view-modal/index';
import { DialogController, DialogService }                 from 'aurelia-dialog';

@inject(AppContainer, ConformityEvaluationsPreparationStageRepository, DiplomasRepository, DialogService, DialogController)
export class ConcludeConformityEvaluationPreparationStageDialog extends BaseFormViewModel {

    @bindable({defaultBindingMode: bindingMode.twoWay}) conformityEvaluation;

    listingId = 'legislation-client-conformity-evaluation-checklist-listing';

    @bindable settings = {
        title: 'form.title.conclude-conformity-evaluation-preparation',
        size:  'modal-full',
    };

    applicableChecklist = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param conformityEvaluationsRepository
     * @param diplomasRepository
     * @param dialogService
     * @param dialogController
     */
    constructor(appContainer, conformityEvaluationsRepository, diplomasRepository, dialogService, dialogController) {
        super(appContainer);

        this.conformityEvaluationsRepository = conformityEvaluationsRepository;
        this.diplomasRepository              = diplomasRepository;
        this.dialogService                   = dialogService;
        this.dialogController                = dialogController;
    }

    /**
     * Handles bind event
     */
    activate(conformityEvaluation) {
        this.conformityEvaluation = conformityEvaluation;

        return this.fetchData().then(() => this.defineDatatable());
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise<any>}
     */
    fetchData(params = null) {
        this.alert = null;

        return this.conformityEvaluationsRepository.applicableChecklist(
            this.conformityEvaluation.applicability_types,
            this.conformityEvaluation.diploma_classifications,
            this.conformityEvaluation.applicable_sectors,
        ).then(response => {
            if (response.status === false) {
                return this.alert = this.alertMessage(response.status, response.message, response.errors);
            }

            return this.applicableChecklist.splice(0, this.applicableChecklist.length, ...response);
        });
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:          {
                search:  () => Promise.resolve({
                    data:            this.applicableChecklist,
                    recordsTotal:    this.applicableChecklist.length,
                    recordsFiltered: this.applicableChecklist.length,
                }),
                destroy: null,
            },
            destroySelected:     false,
            actionsContextMenu:  true,
            actions:             [
                {
                    icon:    'icon-file-eye',
                    tooltip: 'form.button.diploma-details',
                    action:  (row) => this.dialogService.open({
                        viewModel: ViewDiplomaModal,
                        model:     row.diploma,
                    }),
                },
                {
                    icon:    'icon-file-pdf',
                    tooltip: 'form.button.diploma',
                    visible: (row) => row.diploma.viewing_document_file_id !== null,
                    action:  (row) => this.diplomasRepository.downloadFile(row.diploma.viewing_document),
                },
                {
                    icon:    'icon-file-pdf',
                    tooltip: 'form.button.consolidated-document',
                    visible: (row) => row.diploma.consolidated_document_file_id !== null,
                    action:  (row) => this.diplomasRepository.downloadFile(row.diploma.consolidated_document),
                },
            ],
            options:             [],
            buttons:             [],
            selectable:          true,
            showFilter:          false,
            showResultsPerPage:  false,
            showOptionsDropdown: false,
            showInfo:            false,
            showPagination:      false,
            sorting:             {
                column:    1,
                direction: 'asc',
            },
            columns:             [
                {
                    data:      'diploma_name',
                    name:      'diploma',
                    title:     'form.field.diploma',
                    orderable: false,
                },
                {
                    data:      'diploma_classification_name',
                    name:      'diploma_classification_name',
                    title:     'form.field.classification',
                    orderable: false,
                },
                {
                    data:      'applicable_sector_name',
                    name:      'applicable_sector_name',
                    title:     'form.field.applicable-sector',
                    orderable: false,
                },
                {
                    data:      'applicability_type_name',
                    name:      'applicability_type_name',
                    title:     'form.field.applicability-type',
                    orderable: false,
                },
            ],
        };
    }

    /**
     * Cancels the form
     */
    cancel() {
        this.dialogController.close();
    }

    /**
     * Submits the form
     */
    submit() {
        this.dialogController.ok(this.datatable.instance.selectedRows);
    }

}
