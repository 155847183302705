import { inject, transient }                                from 'aurelia-framework';
import { AppContainer }                                     from 'resources/services/app-container';
import { ConformityEvaluation }                             from 'modules/legislation/models/conformity-evaluation';
import { DiplomaClassificationsRepository }                 from 'modules/legislation/management/diploma-classifications/services/repository';
import { ConcludeConformityEvaluationExecutionStageDialog } from 'modules/legislation/client/conformity-evaluations/execution/dialogs/conclude';
import { SendConformityEvaluationToPreviousStageDialog }    from 'modules/legislation/client/conformity-evaluations/execution/dialogs/previous-stage';
import { DialogService }                                    from 'aurelia-dialog';

@transient()
@inject(AppContainer, DiplomaClassificationsRepository, DialogService)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     *Constructor
     *
     * @param appContainer
     * @param diplomaClassificationsRepository
     * @param dialogService
     */
    constructor(appContainer, diplomaClassificationsRepository, dialogService) {
        this.appContainer                     = appContainer;
        this.diplomaClassificationsRepository = diplomaClassificationsRepository;
        this.dialogService                    = dialogService;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ConformityEvaluation}
     */
    model() {
        let model = new ConformityEvaluation();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns general information form schema
     *
     * @param viewModel
     *
     * @returns {[]}
     */
    generalInformationSchema(viewModel) {
        this.title = {
            type:  'text',
            key:   'title',
            label: 'form.field.title',
            size:  12,
        };

        this.purpose = {
            type:  'textarea',
            key:   'purpose',
            label: 'form.field.purpose',
            size:  12,
        };

        this.methodology = {
            type:  'textarea',
            key:   'methodology',
            label: 'form.field.methodology',
            size:  12,
        };

        this.entity = {
            type:  'text',
            key:   'entity',
            label: 'form.field.entity',
            size:  12,
        };

        this.resume = {
            type:  'textarea',
            key:   'resume',
            label: 'form.field.resume',
            size:  12,
        };

        this.report_elaboration_date = {
            key:   'report_elaboration_date',
            type:  'material-ui-date-picker',
            label: 'form.field.report-elaboration-date',
            size:  4,
        };

        return [
            [this.title],
            [this.purpose],
            [this.methodology],
            [this.entity],
            [this.resume],
            [this.report_elaboration_date],
        ];
    }

    /**
     * Returns interveners form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    intervenersSchema(viewModel) {
        this.team_members = {
            type:     'users-duallistbox',
            key:      'team_members',
            label:    'form.field.team-constitution',
            size:     6,
            required: false,
        };

        this.contacted_users = {
            type:     'users-duallistbox',
            key:      'contacted_users',
            label:    'form.field.contacted-people',
            size:     6,
            required: false,
        };

        this.other_team_members = {
            type:     'text',
            key:      'other_team_members',
            label:    'form.field.other-elements',
            size:     6,
            required: false,
        };

        this.other_contacted_people = {
            type:     'text',
            key:      'other_contacted_people',
            label:    'form.field.other-contacted-people',
            size:     6,
            required: false,
        };

        return [
            [this.team_members, this.contacted_users],
            [this.other_team_members, this.other_contacted_people],
        ];
    }

    /**
     * Returns global buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    globalButtonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('legislation.client.conformity-evaluations.execution.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.rollbackButton = {
            type:       'button',
            label:      'form.button.send-to-previous-stage',
            action:     () => {
                this.dialogService
                    .open({viewModel: SendConformityEvaluationToPreviousStageDialog})
                    .whenClosed((dialogResponse) => {
                        if (!dialogResponse.wasCancelled) {
                            viewModel.rollback();
                        }
                    });
            },
            attributes: {
                class: 'btn btn-warning',
            },
            icon:       {
                attributes: {
                    class: 'icon-undo2',
                },
            },
        };

        this.saveButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.submit',
            action:     () => {
                this.dialogService
                    .open({viewModel: ConcludeConformityEvaluationExecutionStageDialog})
                    .whenClosed((dialogResponse) => {
                        if (!dialogResponse.wasCancelled) {
                            viewModel.conclude();
                        }
                    });
            },
            attributes: {
                class: 'btn btn-success',
            },
            icon:       {
                attributes: {
                    class: 'icon-checkmark3',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.rollbackButton,
                this.saveButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
