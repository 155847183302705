import { inject, transient }                                  from 'aurelia-framework';
import { AppContainer }                                       from 'resources/services/app-container';
import { ConformityEvaluation }                               from 'modules/legislation/models/conformity-evaluation';
import { ApplicabilityTypesRepository }                       from 'modules/legislation/management/applicability-types/services/repository';
import { DiplomaClassificationsRepository }                   from 'modules/legislation/management/diploma-classifications/services/repository';
import { CompanyOrganizationsRepository }                     from 'modules/administration/company-organizations/services/repository';
import { ConcludeConformityEvaluationPreparationStageDialog } from 'modules/legislation/client/conformity-evaluations/preparation/dialogs/conclude';
import { DialogService }                                      from 'aurelia-dialog';

@transient()
@inject(AppContainer, ApplicabilityTypesRepository, DiplomaClassificationsRepository, CompanyOrganizationsRepository, DialogService)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        //
    };

    /**
     *Constructor
     *
     * @param appContainer
     * @param applicabilityTypesRepository
     * @param diplomaClassificationsRepository
     * @param companyOrganizationsRepository
     * @param dialogService
     */
    constructor(appContainer, applicabilityTypesRepository, diplomaClassificationsRepository, companyOrganizationsRepository, dialogService) {
        this.appContainer                     = appContainer;
        this.applicabilityTypesRepository     = applicabilityTypesRepository;
        this.diplomaClassificationsRepository = diplomaClassificationsRepository;
        this.companyOrganizationsRepository   = companyOrganizationsRepository;
        this.dialogService                    = dialogService;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ConformityEvaluation}
     */
    model() {
        let model = new ConformityEvaluation();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns general information form schema
     *
     * @param viewModel
     *
     * @returns {[]}
     */
    generalInformationSchema(viewModel) {
        this.title = {
            type:  'text',
            key:   'title',
            label: 'form.field.title',
            size:  12,
        };

        this.purpose = {
            type:  'textarea',
            key:   'purpose',
            label: 'form.field.purpose',
            size:  12,
        };

        this.methodology = {
            type:  'textarea',
            key:   'methodology',
            label: 'form.field.methodology',
            size:  12,
        };

        this.entity = {
            type:  'text',
            key:   'entity',
            label: 'form.field.entity',
            size:  12,
        };

        return [
            [this.title],
            [this.purpose],
            [this.methodology],
            [this.entity],
        ];
    }

    /**
     * Returns interveners form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    intervenersSchema(viewModel) {
        this.team_members = {
            type:     'users-duallistbox',
            key:      'team_members',
            label:    'form.field.team-constitution',
            size:     6,
            required: false,
        };

        this.users_to_contact = {
            type:     'users-duallistbox',
            key:      'users_to_contact',
            label:    'form.field.people-to-contact',
            size:     6,
            required: false,
        };

        this.other_team_members = {
            type:     'text',
            key:      'other_team_members',
            label:    'form.field.other-elements',
            size:     6,
            required: false,
        };

        this.other_people_to_contact = {
            type:     'text',
            key:      'other_people_to_contact',
            label:    'form.field.other-people-to-contact',
            size:     6,
            required: false,
        };

        return [
            [this.team_members, this.users_to_contact],
            [this.other_team_members, this.other_people_to_contact],
        ];
    }

    /**
     * Returns applicability form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    applicabilitySchema(viewModel) {
        this.applicability_types = {
            type:         'multiselect-native',
            key:          'applicability_types',
            id:           'applicability_applicability_types',
            label:        'form.field.applicability-types',
            size:         6,
            remoteSource: this.applicabilityTypesRepository.active.bind(this.applicabilityTypesRepository),
        };

        this.diploma_classifications = {
            type:         'fancytree',
            key:          'diploma_classifications',
            label:        'form.field.classifications',
            size:         12,
            remoteSource: () => this.diplomaClassificationsRepository.tree(true),
            settings:     {
                controlButtons: true,
                selectMode:     3,
            },
        };

        this.applicable_sectors = {
            type:         'fancytree',
            key:          'applicable_sectors',
            label:        'form.field.applicable-sectors',
            size:         12,
            remoteSource: () => this.companyOrganizationsRepository.tree(true),
            settings:     {
                selectMode: 3,
            },
        };

        return [
            [this.applicability_types],
            [this.diploma_classifications],
            [this.applicable_sectors],
        ];
    }

    /**
     * Returns global buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    globalButtonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('legislation.client.conformity-evaluations.preparation.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.saveButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.submit',
            action:     () => {
                this.dialogService
                    .open({
                        viewModel: ConcludeConformityEvaluationPreparationStageDialog,
                        model:     viewModel.model,
                    })
                    .whenClosed((dialogResponse) => {
                        if (!dialogResponse.wasCancelled) {
                            viewModel.conclude(dialogResponse.output);
                        }
                    });
            },
            attributes: {
                class: 'btn btn-success',
            },
            icon:       {
                attributes: {
                    class: 'icon-checkmark3',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.saveButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
