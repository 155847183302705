import { bindable, inject } from 'aurelia-framework';
import { FormSchema }       from 'modules/legislation/client/conformity-evaluations/checklist/diploma-details/form-schema';

@inject(FormSchema)
export class DiplomaDetails {

    @bindable model;

    /**
     * Constructor
     *
     * @param formSchema
     */
    constructor(formSchema) {
        this.formSchema = formSchema;
    }

    /**
     * Handles bind event
     */
    bind() {
        this.schema = this.formSchema.schema(this);
    }

    /**
     * Handles attached event
     */
    attached() {
        this.handleTextareasAutosize();
    }

    /**
     * Handles textareas autosize
     */
    handleTextareasAutosize() {
        let self = this;

        $('#collapse-diploma-details').bind('cssClassChanged', function () {
            setTimeout(() => {
                if ($(this).hasClass('show')) {
                    self.formSchema.diploma_summary.instance.refreshAutosize();
                    self.formSchema.diploma_resume.instance.refreshAutosize();
                    self.formSchema.applicability_notes.instance.refreshAutosize();
                }
            }, 0);
        });
    }

}
