import { bindable, inject }                                from 'aurelia-framework';
import { AppContainer }                                    from 'resources/services/app-container';
import { ApplicabilityTypesRepository }                    from 'modules/legislation/management/applicability-types/services/repository';
import { BaseFormViewModel }                               from 'base-form-view-model';
import { BooleanOption }                                   from 'modules/administration/models/boolean-option';
import { CompanyOrganizationsRepository }                  from 'modules/administration/company-organizations/services/repository';
import { ConformityEvaluationsPreparationStageRepository } from 'modules/legislation/client/conformity-evaluations/preparation/services/repository';
import { DiplomaClassificationsRepository }                from 'modules/legislation/management/diploma-classifications/services/repository';
import { ConformityEvaluationStatusesRepository }          from 'modules/legislation/management/conformity-evaluation-statuses/services/repository';
import { FormSchema }                                      from 'modules/legislation/client/conformity-evaluations/report/form-schema';
import { DialogController }                                from 'aurelia-dialog';

@inject(AppContainer, ConformityEvaluationsPreparationStageRepository, CompanyOrganizationsRepository, DiplomaClassificationsRepository, ConformityEvaluationStatusesRepository, ApplicabilityTypesRepository, FormSchema, DialogController)
export class GenerateConformityEvaluationReport extends BaseFormViewModel {

    formId = 'legislation-client-conformity-evaluations-generate-report-form';

    @bindable settings = {
        buttons: false,
        size:    'modal-full',
        title:   'form.title.view-record',
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param companyOrganizationsRepository
     * @param repository
     * @param dialogController
     * @param diplomaClassificationsRepository
     * @param formSchema
     * @param conformityEvaluationStatusesRepository
     * @param applicabilityTypesRepository
     */
    constructor(appContainer, repository, companyOrganizationsRepository, diplomaClassificationsRepository, conformityEvaluationStatusesRepository, applicabilityTypesRepository, formSchema, dialogController) {
        super(appContainer);

        this.repository                             = repository;
        this.companyOrganizationsRepository         = companyOrganizationsRepository;
        this.diplomaClassificationsRepository       = diplomaClassificationsRepository;
        this.conformityEvaluationStatusesRepository = conformityEvaluationStatusesRepository;
        this.applicabilityTypesRepository           = applicabilityTypesRepository;
        this.dialogController                       = dialogController;
        this.formSchema                             = formSchema;
        this.self                                   = this;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return true;
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstructions) {
        super.activate(navigationInstructions);

        this.settings.title = this.appContainer.i18n.tr('form.title.generate-report') + ' ' + params.title;

        this.model    = this.formSchema.model();
        this.model.id = params.id;

        this.schema       = this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);
    }

    /**
     * Download filtered report
     */
    downloadFilteredReport() {
        this.alert = null;

        if (this.validateFields()) {
            this.repository.downloadFilteredReport(this.model.id, this.model);
        }
    }

    /**
     * Validates if required fields are filled
     *
     * @return {boolean}
     */
    validateFields() {
        let booleanOptionFieldsFilled =
                this.isBooleanOption(this.model.include_actions) &&
                this.isBooleanOption(this.model.include_legal_connections) &&
                this.isBooleanOption(this.model.include_resume) &&
                this.isBooleanOption(this.model.include_schedule);

        if (!booleanOptionFieldsFilled) {
            this.alert = this.dangerAlertMessage(
                this.appContainer.i18n.tr('form.message.failure.action-failed'),
                `<li>${this.appContainer.i18n.tr('validation.message.ensure-required-fields-are-filed-in')}</li>`,
            );
        }

        return booleanOptionFieldsFilled;
    }

    /**
     * Returns if the boolean option field is filled
     *
     * @param field
     *
     * @returns {boolean}
     */
    isBooleanOption(field) {
        return [BooleanOption.YES, BooleanOption.NO].includes(field);
    }

}
