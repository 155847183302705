import { inject }                                        from 'aurelia-framework';
import { AppContainer }                                  from 'resources/services/app-container';
import { BaseFormViewModel }                             from 'base-form-view-model';
import { ConformityEvaluationsExecutionStageRepository } from 'modules/legislation/client/conformity-evaluations/execution/services/repository';
import { FormSchema }                                    from 'modules/legislation/client/conformity-evaluations/execution/form-schema';

@inject(AppContainer, ConformityEvaluationsExecutionStageRepository, FormSchema)
export class ConformityEvaluationExecutionForm extends BaseFormViewModel {

    headerTitle = 'form.title.edit-record';
    formId      = 'legislation-client-conformity-evaluations-execution-stage-edition-form';

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.client.conformity-evaluations.manage',
            'legislation.client.conformity-evaluations.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.repository.find(params.id).then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);

            this.generalInformationSchema = this.formSchema.generalInformationSchema(this);
            this.intervenersSchema        = this.formSchema.intervenersSchema(this);
            this.globalButtonsSchema      = this.formSchema.globalButtonsSchema(this);
        });
    }

    /**
     * Handles attached event
     */
    attached() {
        super.attached();

        this.headerSubtitle = this.model.title;
    }

    /**
     * Updates conformity evaluation and concludes it
     */
    conclude() {
        this.alert = null;

        this.repository.updateAndConclude(this.model.id, this.model)
            .then((response) => this.defaultFormSubmittedCallback(response, 'legislation.client.conformity-evaluations.execution.index'))
            .catch(console.log.bind(console));
    }

    /**
     * Rollback to preparation stage
     */
    rollback() {
        this.alert = null;

        this.repository.rollback(this.model.id)
            .then((response) => this.defaultFormSubmittedCallback(response, 'legislation.client.conformity-evaluations.execution.index'))
            .catch(console.log.bind(console));
    }
}
