import { inject, transient }                      from 'aurelia-framework';
import { AppContainer }                           from 'resources/services/app-container';
import { ConformityEvaluationLine }               from 'modules/legislation/models/conformity-evaluation-line';
import { ConformityEvaluationStatusesRepository } from 'modules/legislation/management/conformity-evaluation-statuses/services/repository';

@transient()
@inject(AppContainer, ConformityEvaluationStatusesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        //
    };

    /**
     *Constructor
     *
     * @param appContainer
     * @param conformityEvaluationStatusesRepository
     */
    constructor(appContainer, conformityEvaluationStatusesRepository) {
        this.appContainer                           = appContainer;
        this.conformityEvaluationStatusesRepository = conformityEvaluationStatusesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ConformityEvaluationLine}
     */
    model() {
        let model = new ConformityEvaluationLine();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns the form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.status_id = {
            type:         'select2',
            key:          'status_id',
            id:           'conformity_evaluation_line_status_id',
            label:        'form.field.status',
            size:         4,
            options:      [],
            remoteSource: this.conformityEvaluationStatusesRepository.active.bind(this.conformityEvaluationStatusesRepository),
        };

        this.evidences = {
            type:  'textarea',
            key:   'evidences',
            id:    'conformity_evaluation_line_evidences',
            label: 'form.field.evidences',
            size:  12,
        };

        return [
            [this.status_id],
            [this.evidences],
        ];
    }

}
