import { bindable, inject }                              from 'aurelia-framework';
import { AppContainer }                                  from 'resources/services/app-container';
import { ListConformityEvaluations }                     from 'modules/legislation/client/conformity-evaluations/index';
import { ConformityEvaluationsExecutionStageRepository } from 'modules/legislation/client/conformity-evaluations/execution/services/repository';
import { FilterFormSchema }                              from 'modules/legislation/client/conformity-evaluations/filter-form-schema';
import { DialogService }                                 from 'aurelia-dialog';

@inject(AppContainer, ConformityEvaluationsExecutionStageRepository, FilterFormSchema, DialogService)
export class ListExecutionStageConformityEvaluations extends ListConformityEvaluations {

    headerTitle = 'listing.legislation.conformity-evaluations.in-execution';
    listingId   = 'legislation-client-conformity-evaluations-execution-stage-listing';

    @bindable editRecordRoute = 'legislation.client.conformity-evaluations.execution.edit';
    @bindable viewRecordRoute = 'legislation.client.conformity-evaluations.execution.view';

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filterFormSchema
     * @param dialogService
     */
    constructor(appContainer, repository, filterFormSchema, dialogService) {
        super(appContainer, repository, filterFormSchema, dialogService);
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.client.conformity-evaluations.manage',
            'legislation.client.conformity-evaluations.view',
        ]);
    }

}
