import { BaseModel } from 'modules/core/models/base-model';

export class FormSchema {

    /**
     * Returns a new instance of the model
     *
     * @returns {BaseModel}
     */
    model() {
        return new BaseModel();
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {[]}
     */
    schema(viewModel) {
        this.include_checklist_attachments = {
            type:  'boolean-options-select',
            key:   'include_checklist_attachments',
            label: 'form.field.include-checklist-attachments',
            size:  12,
        };

        return [
            [this.include_checklist_attachments],
        ];
    }

}
