import { inject }                           from 'aurelia-framework';
import { BaseFilterFormSchema }             from 'resources/classes/base-filter-form-schema';
import { LocalStorage }                     from 'resources/services/local-storage';
import { EventAggregator }                  from 'aurelia-event-aggregator';
import { I18N }                             from 'aurelia-i18n';
import { DialogService }                    from 'aurelia-dialog';
import { DiplomaClassificationsRepository } from 'modules/legislation/management/diploma-classifications/services/repository';
import { UsersRepository }                  from 'modules/administration/users/services/repository';
import { TreeModal }                        from 'modules/administration/tree-modal/index';

@inject(LocalStorage, EventAggregator, I18N, DialogService, DiplomaClassificationsRepository, UsersRepository)
export class FilterFormSchema extends BaseFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param storage
     * @param eventAggregator
     * @param i18n
     * @param dialogService
     * @param diplomaClassificationsRepository
     * @param usersRepository
     */
    constructor(storage, eventAggregator, i18n, dialogService, diplomaClassificationsRepository, usersRepository) {
        super(storage, eventAggregator, i18n, dialogService);

        this.diplomaClassificationsRepository = diplomaClassificationsRepository;
        this.usersRepository                  = usersRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {Client}
     */
    model(viewModel) {
        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns client data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.title = {
            type:     'text',
            key:      'title',
            label:    'form.field.title',
            size:     4,
            required: false,
        };

        this.created_at_from = {
            type:     'material-ui-date-picker',
            key:      'created_at_from',
            label:    'form.field.created-at-from',
            size:     4,
            required: false,
            settings: {
                selectYears:  100,
                selectMonths: true,
            },
        };

        this.created_at_to = {
            type:     'material-ui-date-picker',
            key:      'created_at_to',
            label:    'form.field.created-at-to',
            size:     4,
            required: false,
            settings: {
                selectYears:  100,
                selectMonths: true,
            },
        };

        this.diploma_classification_id = {
            type:       'diploma-classifications-select',
            key:        'diploma_classification_id',
            label:      'form.field.classification',
            size:       8,
            required:   false,
            inputGroup: {
                position: 'right',
                button:   {
                    action: () => this.dialogService.open({
                        viewModel: TreeModal,
                        model:     {
                            repository: {
                                method: 'tree',
                                source: this.diplomaClassificationsRepository,
                            },
                            onClick:    (event, data) => viewModel.filterModel.diploma_classification_id = data.node.key,
                        },
                    }),
                    icon:   {
                        class: 'icon-tree5',
                    },
                },
            },
        };

        this.created_by = {
            type:         'select2',
            key:          'created_by',
            label:        'form.field.created-by',
            size:         4,
            remoteSource: this.usersRepository.active.bind(this.usersRepository),
            required:     false,
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', {
                listingId: viewModel.listingId,
                criteria:  viewModel.filterModel,
            }),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', viewModel.listingId),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            [this.title, this.created_at_from, this.created_at_to],
            [this.diploma_classification_id, this.created_by],
            [this.buttons],
        ];
    }

}
