import { inject, transient }                      from 'aurelia-framework';
import { BaseFilterFormSchema }                   from 'resources/classes/base-filter-form-schema';
import { LocalStorage }                           from 'resources/services/local-storage';
import { EventAggregator }                        from 'aurelia-event-aggregator';
import { I18N }                                   from 'aurelia-i18n';
import { DialogService }                          from 'aurelia-dialog';
import { DiplomaClassificationsRepository }       from 'modules/legislation/management/diploma-classifications/services/repository';
import { ApplicabilityTypesRepository }           from 'modules/legislation/management/applicability-types/services/repository';
import { ConformityEvaluationStatusesRepository } from 'modules/legislation/management/conformity-evaluation-statuses/services/repository';
import { CompanyOrganizationsRepository }         from 'modules/administration/company-organizations/services/repository';
import { TreeModalMultiselect }                   from 'modules/administration/tree-modal-multiselect/index';

@transient()
@inject(LocalStorage, EventAggregator, I18N, DialogService, DiplomaClassificationsRepository, ApplicabilityTypesRepository, ConformityEvaluationStatusesRepository, CompanyOrganizationsRepository)
export class FilterFormSchema extends BaseFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        diploma_classifications: [],
        applicable_sectors:      [],
        applicability_types:     [],
        statuses:                [],
    };

    /**
     * Constructor
     *
     * @param storage
     * @param eventAggregator
     * @param i18n
     * @param dialogService
     * @param diplomaClassificationsRepository
     * @param applicabilityTypesRepository
     * @param conformityEvaluationStatusesRepository
     * @param applicableSectorsRepository
     */
    constructor(storage, eventAggregator, i18n, dialogService, diplomaClassificationsRepository, applicabilityTypesRepository, conformityEvaluationStatusesRepository, applicableSectorsRepository) {
        super(storage, eventAggregator, i18n, dialogService);

        this.diplomaClassificationsRepository       = diplomaClassificationsRepository;
        this.applicabilityTypesRepository           = applicabilityTypesRepository;
        this.conformityEvaluationStatusesRepository = conformityEvaluationStatusesRepository;
        this.applicableSectorsRepository            = applicableSectorsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {Client}
     */
    model(viewModel) {
        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns client data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.diploma_classification_id = {
            type:         'multiselect-native',
            key:          'diploma_classifications',
            label:        'form.field.classification',
            remoteSource: () => this.diplomaClassificationsRepository.active(),
            size:         12,
            required:     false,
            inputGroup:   {
                position: 'right',
                button:   {
                    action: () => this.dialogService.open({
                        viewModel: TreeModalMultiselect,
                        model:     {
                            model:        viewModel.filterModel,
                            key:          'diploma_classifications',
                            label:        'form.field.classification',
                            remoteSource: () => this.diplomaClassificationsRepository.tree(),
                            settings:     {
                                selectMode: 3,
                            },
                        },
                    }),
                    icon:   {
                        class: 'icon-tree5',
                    },
                },
            },
        };

        this.applicable_sector_id = {
            type:           'multiselect-native',
            key:            'applicable_sectors',
            label:          'form.field.applicable-sector',
            size:           12,
            remoteSource:   () => this.applicableSectorsRepository.active(),
            processResults: (item) => {
                item.name = item.acronym + ' - ' + item.name;

                return item;
            },
            required:       false,
            inputGroup:     {
                position: 'right',
                button:   {
                    action: () => this.dialogService.open({
                        viewModel: TreeModalMultiselect,
                        model:     {
                            model:        viewModel.filterModel,
                            key:          'applicable_sectors',
                            label:        'form.field.applicable-sector',
                            remoteSource: () => this.applicableSectorsRepository.tree(),
                            settings:     {
                                selectMode: 3,
                            },
                        },
                    }),
                    icon:   {
                        class: 'icon-tree5',
                    },
                },
            },
        };

        this.applicability_type_id = {
            type:         'multiselect-native',
            key:          'applicability_types',
            label:        'form.field.applicability-type',
            size:         6,
            remoteSource: this.applicabilityTypesRepository.active.bind(this.applicabilityTypesRepository),
            required:     false,
        };

        this.status_id = {
            type:         'multiselect-native',
            key:          'statuses',
            label:        'form.field.status',
            size:         6,
            remoteSource: this.conformityEvaluationStatusesRepository.active.bind(this.conformityEvaluationStatusesRepository),
            required:     false,
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', {
                listingId: viewModel.listingId,
                criteria:  viewModel.filterModel,
            }),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', viewModel.listingId),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            [this.diploma_classification_id],
            [this.applicable_sector_id],
            [this.applicability_type_id, this.status_id],
            [this.buttons],
        ];
    }

}
