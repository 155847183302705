import { inject, transient }                from 'aurelia-framework';
import { AppContainer }                     from 'resources/services/app-container';
import { ConformityEvaluation }             from 'modules/legislation/models/conformity-evaluation';
import { DiplomaClassificationsRepository } from 'modules/legislation/management/diploma-classifications/services/repository';

@transient()
@inject(AppContainer, DiplomaClassificationsRepository)
export class FormSchema {

    currentStep = 1;

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        //
    };

    /**
     *Constructor
     *
     * @param appContainer
     * @param diplomaClassificationsRepository
     */
    constructor(appContainer, diplomaClassificationsRepository) {
        this.appContainer                     = appContainer;
        this.diplomaClassificationsRepository = diplomaClassificationsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ConformityEvaluation}
     */
    model() {
        let model = new ConformityEvaluation();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns general information form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    generalInformationSchema(viewModel) {
        this.title = {
            type:       'text',
            key:        'title',
            label:      'form.field.title',
            size:       12,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.purpose = {
            type:       'textarea',
            key:        'purpose',
            label:      'form.field.purpose',
            size:       12,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.methodology = {
            type:       'textarea',
            key:        'methodology',
            label:      'form.field.methodology',
            size:       12,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.entity = {
            type:       'text',
            key:        'entity',
            label:      'form.field.entity',
            size:       12,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.resume = {
            type:       'textarea',
            key:        'resume',
            label:      'form.field.resume',
            size:       12,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.report_elaboration_date = {
            key:        'report_elaboration_date',
            type:       'material-ui-date-picker',
            label:      'form.field.report-elaboration-date',
            size:       4,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        return [
            [this.title],
            [this.purpose],
            [this.methodology],
            [this.entity],
            [this.resume],
            [this.report_elaboration_date],
        ];
    }

    /**
     * Returns interveners form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    intervenersSchema(viewModel) {
        this.team_members = {
            type:       'users-duallistbox',
            key:        'team_members',
            label:      'form.field.team-constitution',
            size:       6,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.contacted_users = {
            type:       'users-duallistbox',
            key:        'contacted_users',
            label:      'form.field.contacted-people',
            size:       6,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.other_team_members = {
            type:       'text',
            key:        'other_team_members',
            label:      'form.field.other-elements',
            size:       6,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.other_contacted_people = {
            type:       'text',
            key:        'other_contacted_people',
            label:      'form.field.other-contacted-people',
            size:       6,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        return [
            [this.team_members, this.contacted_users],
            [this.other_team_members, this.other_contacted_people],
        ];
    }

    /**
     * Returns global buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    globalButtonsSchema(viewModel) {

        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('legislation.client.conformity-evaluations.execution.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
