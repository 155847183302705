import { ConformityEvaluationsRepository } from 'modules/legislation/client/conformity-evaluations/services/repository';

export class ConformityEvaluationsExecutedStageRepository extends ConformityEvaluationsRepository {

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post('legislation/client/conformity-evaluations/executed/search', criteria);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get(`legislation/client/conformity-evaluations/${id}/executed`);
    }

}
