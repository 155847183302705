import { ConformityEvaluationsRepository } from 'modules/legislation/client/conformity-evaluations/services/repository';

export class ConformityEvaluationsPreparationStageRepository extends ConformityEvaluationsRepository {

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post('legislation/client/conformity-evaluations/preparation/search', criteria);
    }

    /**
     * Creates a record
     *
     * @param data
     *
     * @returns {*}
     */
    create(data) {
        return this.httpClient.post('legislation/client/conformity-evaluations/preparation', data);
    }

    /**
     * Creates a record
     *
     * @param data
     *
     * @returns {*}
     */
    createAndConclude(data) {
        return this.httpClient.post('legislation/client/conformity-evaluations/preparation/conclude', data);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get(`legislation/client/conformity-evaluations/${id}/preparation`);
    }

    /**
     * Updates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(id, data) {
        return this.httpClient.put(`legislation/client/conformity-evaluations/${id}/preparation`, data);
    }

    /**
     * Updates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    updateAndConclude(id, data) {
        return this.httpClient.put(`legislation/client/conformity-evaluations/${id}/preparation/conclude`, data);
    }

    /**
     * Fetches applicable checklist for a given set of applicability types, diploma classifications & applicable sectors
     *
     * @param applicabilityTypes
     * @param diplomaClassifications
     * @param applicableSectors
     *
     * @returns {*}
     */
    applicableChecklist(applicabilityTypes, diplomaClassifications, applicableSectors) {
        return this.httpClient.post('legislation/client/conformity-evaluations/preparation/applicable-checklist', {
            applicability_types:     applicabilityTypes,
            diploma_classifications: diplomaClassifications,
            applicable_sectors:      applicableSectors,
        });
    }

}
