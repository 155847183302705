import { bindable, inject }                        from 'aurelia-framework';
import { AppContainer }                            from 'resources/services/app-container';
import { BaseViewModel }                           from 'base-view-model';
import { ConformityEvaluationLinesRepository }     from 'modules/legislation/client/conformity-evaluations/checklist/services/repository';
import { ConformityEvaluationLineFilesRepository } from 'modules/legislation/client/conformity-evaluations/checklist/services/files-repository';
import { FormSchema }                              from 'modules/legislation/client/conformity-evaluations/checklist/edit/form-schema';
import { DialogController }                        from 'aurelia-dialog';
import environment                                 from 'environment';

@inject(AppContainer, ConformityEvaluationLinesRepository, ConformityEvaluationLineFilesRepository, FormSchema, DialogController)
export class EditConformityEvaluationModal extends BaseViewModel {

    @bindable settings = {
        title: 'form.title.conformity-evaluation',
        size:  'modal-full',
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filesRepository
     * @param formSchema
     * @param dialogController
     */
    constructor(appContainer, repository, filesRepository, formSchema, dialogController) {
        super(appContainer);

        this.repository       = repository;
        this.filesRepository  = filesRepository;
        this.formSchema       = formSchema;
        this.dialogController = dialogController;
    }

    /**
     * Handles bind event
     *
     * @param params
     * @param navigationInstruction
     *
     * @returns {Promise}
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.repository.find(params.id).then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);

            this.schema = this.formSchema.schema(this);

            this.settings.subtitle = response.meta.diploma_name;

            if (environment.copyApplicabilityNotesToEvidences && !this.model.evidences) {
                this.model.evidences        = response.meta.applicability_notes;
                this.initialModel.evidences = response.meta.applicability_notes;
            }
        });
    }

    /**
     * Handles form submission
     */
    submit() {
        this.alert = null;

        return this.repository.update(this.model.id, this.model)
            .then((response) => {
                this.alert = this.alertMessage(response.status, response.message, response.errors);

                if (response.status === true) {
                    this.appContainer.notifier.successNotice();
                }

                return response.status;
            }).catch((error) => console.log(error));
    }

}
