import { bindable, bindingMode, inject }              from 'aurelia-framework';
import { AppContainer }                               from 'resources/services/app-container';
import { ConformityEvaluationLinesRepository }        from 'modules/legislation/client/conformity-evaluations/checklist/services/repository';
import { DiplomasRepository }                         from 'modules/legislation/diplomas/services/repository';
import { ViewDiplomaModal }                           from 'modules/legislation/diplomas/view-modal/index';
import { EditConformityEvaluationModal }              from 'modules/legislation/client/conformity-evaluations/checklist/edit/index';
import { ViewConformityEvaluationModal }              from 'modules/legislation/client/conformity-evaluations/checklist/view/index';
import { RefreshConformityEvaluationChecklistDialog } from 'modules/legislation/client/conformity-evaluations/checklist/dialogs/refresh';
import { FilterFormSchema }                           from 'modules/legislation/client/conformity-evaluations/checklist/filter-form-schema';
import { DialogService }                              from 'aurelia-dialog';

@inject(AppContainer, ConformityEvaluationLinesRepository, DiplomasRepository, FilterFormSchema, DialogService)
export class ChecklistCustomElement {

    @bindable listingId = 'legislation-client-conformity-evaluations-execution-stage-listing';

    @bindable({defaultBindingMode: bindingMode.twoWay}) conformityEvaluation;
    @bindable({defaultBindingMode: bindingMode.twoWay}) readonly = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param conformityEvaluationLinesRepository
     * @param diplomasRepository
     * @param filterFormSchema
     * @param dialogService
     */
    constructor(appContainer, conformityEvaluationLinesRepository, diplomasRepository, filterFormSchema, dialogService) {
        this.appContainer = appContainer;

        this.conformityEvaluationLinesRepository = conformityEvaluationLinesRepository;
        this.diplomasRepository                  = diplomasRepository;
        this.filterFormSchema                    = filterFormSchema;
        this.dialogService                       = dialogService;
    }

    /**
     * Handles bind event
     */
    bind() {
        this.filterModel  = this.filterFormSchema.model(this);
        this.filterSchema = this.filterFormSchema.schema(this);

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:         {
                search:          (criteria) => this.conformityEvaluationLinesRepository.search(this.conformityEvaluation.id, criteria),
                destroy:         (id) => this.conformityEvaluationLinesRepository.destroy(id),
                destroySelected: (ids) => this.conformityEvaluationLinesRepository.destroySelected(ids),
            },
            destroy:            true,
            show:               {
                icon:    'icon-stack-check',
                tooltip: 'form.button.conformity-evaluation',
                visible: () => this.readonly,
                action:  (row) => this.dialogService.open({
                    viewModel: ViewConformityEvaluationModal,
                    model:     row,
                }),
            },
            edit:               {
                icon:    'icon-stack-check',
                tooltip: 'form.button.conformity-evaluation',
                visible: () => !this.readonly,
                action:  (row) => this.dialogService.open({
                    viewModel: EditConformityEvaluationModal,
                    model:     row,
                }).whenClosed((response) => {
                    if (!response.wasCancelled) {
                        this.datatable.instance.reload();
                    }
                }),
            },
            actionsContextMenu: true,
            actions:            [
                {
                    icon:    'icon-file-eye',
                    tooltip: 'form.button.diploma-details',
                    action:  (row) => this.dialogService.open({
                        viewModel: ViewDiplomaModal,
                        model:     row.diploma,
                    }),
                },
                {
                    icon:    'icon-file-pdf',
                    tooltip: 'form.button.diploma',
                    visible: (row) => row.diploma.viewing_document_file_id !== null,
                    action:  (row) => this.diplomasRepository.downloadFile(row.diploma.viewing_document),
                },
                {
                    icon:    'icon-file-pdf',
                    tooltip: 'form.button.consolidated-document',
                    visible: (row) => row.diploma.consolidated_document_file_id !== null,
                    action:  (row) => this.diplomasRepository.downloadFile(row.diploma.consolidated_document),
                },
            ],
            options:            [],
            buttons:            [
                {
                    label:        'form.button.refresh',
                    button_color: 'primary',
                    icon:         'icon-sync',
                    visible:      () => !this.readonly,
                    action:       () => {
                        this.dialogService
                            .open({viewModel: RefreshConformityEvaluationChecklistDialog})
                            .whenClosed((dialogResponse) => {
                                if (!dialogResponse.wasCancelled) {
                                    this.conformityEvaluationLinesRepository.refresh(this.conformityEvaluation.id).then((response) => {
                                        if (response.status === true) {
                                            this.appContainer.notifier.successNotice();
                                            this.datatable.instance.reload();
                                        }
                                    });
                                }
                            });
                    },
                },
            ],
            selectable:         true,
            sorting:            {
                column:    0,
                direction: 'asc',
            },
            columns:            [
                {
                    data:   'diploma_name',
                    name:   'diplomas.name',
                    title:  'form.field.diploma',
                    nowrap: true,
                },
                {
                    data:  'diploma_classification_name',
                    name:  'diploma_classifications.name',
                    title: 'form.field.classification',
                },
                {
                    data:  'applicable_sector_name',
                    name:  'company_organizations.acronym',
                    title: 'form.field.applicable-sector',
                },
                {
                    data:  'applicability_type_name',
                    name:  'applicability_types.name',
                    title: 'form.field.applicability-type',
                },
                {
                    data:       'status',
                    name:       'conformity_evaluation_statuses.name',
                    title:      'form.field.status',
                    type:       'stoplight',
                    icon_eval:  (row) => row.status ? row.status.icon : null,
                    color_eval: (row) => 'text-primary',
                },
            ],
        };
    }

}
