import { ConformityEvaluationsRepository } from 'modules/legislation/client/conformity-evaluations/services/repository';

export class ConformityEvaluationsExecutionStageRepository extends ConformityEvaluationsRepository {

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post('legislation/client/conformity-evaluations/execution/search', criteria);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get(`legislation/client/conformity-evaluations/${id}/execution`);
    }

    /**
     * Rollbacks a record
     *
     * @param id
     *
     * @returns {*}
     */
    rollback(id) {
        return this.httpClient.patch(`legislation/client/conformity-evaluations/${id}/execution/rollback`);
    }

    /**
     * Updates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(id, data) {
        return this.httpClient.put(`legislation/client/conformity-evaluations/${id}/execution`, data);
    }

    /**
     * Updates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    updateAndConclude(id, data) {
        return this.httpClient.put(`legislation/client/conformity-evaluations/${id}/execution/conclude`, data);
    }
}
