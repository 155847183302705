import { inject, transient }                from 'aurelia-framework';
import { AppContainer }                     from 'resources/services/app-container';
import { ConformityEvaluation }             from 'modules/legislation/models/conformity-evaluation';
import { DiplomaClassificationsRepository } from 'modules/legislation/management/diploma-classifications/services/repository';
import { CompanyOrganizationsRepository }   from 'modules/administration/company-organizations/services/repository';
import { ConformityEvaluationStage }        from 'modules/legislation/models/conformity-evaluation-stage';

@transient()
@inject(AppContainer, DiplomaClassificationsRepository, CompanyOrganizationsRepository)
export class FormSchema {

    /**
     * Constructor
     *
     * @param appContainer
     * @param diplomaClassificationsRepository
     * @param companyOrganizationsRepository
     */
    constructor(appContainer, diplomaClassificationsRepository, companyOrganizationsRepository) {
        this.appContainer                     = appContainer;
        this.diplomaClassificationsRepository = diplomaClassificationsRepository;
        this.companyOrganizationsRepository   = companyOrganizationsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ConformityEvaluation}
     */
    model() {
        return new ConformityEvaluation();
    }

    /**
     * Returns general information form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    generalInformationSchema(viewModel) {
        this.title = {
            type:       'text',
            key:        'title',
            label:      'form.field.title',
            size:       12,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.purpose = {
            type:       'textarea',
            key:        'purpose',
            label:      'form.field.purpose',
            size:       12,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.methodology = {
            type:       'textarea',
            key:        'methodology',
            label:      'form.field.methodology',
            size:       12,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.entity = {
            type:       'text',
            key:        'entity',
            label:      'form.field.entity',
            size:       12,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        return [
            [this.title],
            [this.purpose],
            [this.methodology],
            [this.entity],
        ];
    }

    /**
     * Returns interveners form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    intervenersSchema(viewModel) {
        this.team_members = {
            type:       'users-duallistbox',
            key:        'team_members',
            label:      'form.field.team-constitution',
            size:       6,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.users_to_contact = {
            type:       'users-duallistbox',
            key:        'users_to_contact',
            label:      'form.field.people-to-contact',
            size:       6,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.other_team_members = {
            type:       'text',
            key:        'other_team_members',
            label:      'form.field.other-elements',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.other_people_to_contact = {
            type:       'text',
            key:        'other_people_to_contact',
            label:      'form.field.other-people-to-contact',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        return [
            [this.team_members, this.users_to_contact],
            [this.other_team_members, this.other_people_to_contact],
        ];
    }

    /**
     * Returns scope form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    scopeSchema(viewModel) {
        this.diploma_classifications = {
            type:         'fancytree',
            key:          'diploma_classifications',
            label:        'form.field.classifications',
            size:         12,
            options:      [],
            remoteSource: this.diplomaClassificationsRepository.tree.bind(this.diplomaClassificationsRepository),
            required:     false,
            settings:     {
                disabled: true,
            },
        };

        return [
            [this.diploma_classifications],
        ];
    }

    /**
     * Returns applicable sectors form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    applicableSectorsSchema(viewModel) {
        this.applicable_sectors = {
            type:         'fancytree',
            key:          'applicable_sectors',
            label:        'form.field.applicable-sectors',
            size:         12,
            options:      [],
            remoteSource: this.companyOrganizationsRepository.tree.bind(this.companyOrganizationsRepository),
            required:     false,
            settings:     {
                disabled: true,
            },
        };

        return [
            [this.applicable_sectors],
        ];
    }

    /**
     * Returns global buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    globalButtonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => this.handleBackButton(viewModel),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

    /**
     * Returns "back" route
     *
     * @param viewModel
     *
     * @return string
     */
    handleBackButton(viewModel) {
        // this first stage is not supposed to be used
        if (viewModel.model.stage_id === ConformityEvaluationStage.IN_PREPARATION) {
            return this.appContainer.router.navigateToRoute('legislation.client.conformity-evaluations.preparation.index');
        } else if (viewModel.model.stage_id === ConformityEvaluationStage.IN_EXECUTION) {
            return this.appContainer.router.navigateToRoute('legislation.client.conformity-evaluations.execution.index');
        } else if (viewModel.model.stage_id === ConformityEvaluationStage.EXECUTED) {
            return this.appContainer.router.navigateToRoute('legislation.client.conformity-evaluations.executed.index');
        }
    }

}
