import { inject, PLATFORM }                                from 'aurelia-framework';
import { AppContainer }                                    from 'resources/services/app-container';
import { BaseFormViewModel }                               from 'base-form-view-model';
import { ConformityEvaluationsPreparationStageRepository } from 'modules/legislation/client/conformity-evaluations/preparation/services/repository';
import { FormSchema }                                      from 'modules/legislation/client/conformity-evaluations/preparation/form-schema';

@inject(AppContainer, ConformityEvaluationsPreparationStageRepository, FormSchema)
export class ConformityEvaluationPreparationForm extends BaseFormViewModel {

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/legislation/client/conformity-evaluations/preparation/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.conformity-evaluations.manage',
            'legislation.conformity-evaluations.create',
            'legislation.conformity-evaluations.edit',
        ]);
    }

    /**
     * Handles activate event
     *
     * @returns {Promise}
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => {
            this.generalInformationSchema = this.formSchema.generalInformationSchema(this);
            this.intervenersSchema        = this.formSchema.intervenersSchema(this);
            this.applicabilitySchema      = this.formSchema.applicabilitySchema(this);
            this.globalButtonsSchema      = this.formSchema.globalButtonsSchema(this);
        });
    }

    /**
     * Handles attached event
     */
    attached() {
        super.attached();

        this.headerSubtitle = this.model.title;
    }

}
