import { ConformityEvaluationPreparationForm as BaseConformityEvaluationPreparationForm } from 'modules/legislation/client/conformity-evaluations/preparation/form';

export class EditConformityEvaluation extends BaseConformityEvaluationPreparationForm {

    headerTitle = 'form.title.edit-record';
    formId      = 'legislation-client-conformity-evaluations-preparation-stage-edition-form';

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.client.conformity-evaluations.manage',
            'legislation.client.conformity-evaluations.edit',
        ]);
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise<unknown[]>}
     */
    fetchData(params) {
        return this.repository.find(params.id).then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);
        });
    }

    /**
     * Updates conformity evaluation and concludes it
     */
    conclude(checklist = []) {
        this.alert = null;

        this.model.checklist = checklist;

        this.repository.updateAndConclude(this.model.id, this.model)
            .then((response) => this.defaultFormSubmittedCallback(response, 'legislation.client.conformity-evaluations.preparation.index'))
            .catch(console.log.bind(console));
    }

}
